/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {

    product_titles_red: function(){
      var titles = document.querySelectorAll('.product-info h6, .single .product_title');
      titles.forEach(function(title){
        var string = title.textContent;
        var substringCup = 'Cup';
        var substringOz = 'oz.';
        var substringCone = 'Cone';

        if (string.indexOf(substringCup) !== -1){
          var Text = string.split(substringCup)[0]+substringCup;
            title.innerHTML = '<span class="red-text">'+Text+'</span>' + string.split(substringCup).pop();
            Text = '';
          }

        if (string.indexOf(substringOz) !== -1){
          var Text = string.split(substringOz)[0]+substringOz;
            title.innerHTML = '<span class="red-text">'+Text+'</span>' + string.split(substringOz).pop();
            Text = '';
          }

        if (string.indexOf(substringCone) !== -1){
          var Text = string.split(substringCone)[0]+substringCone;
            title.innerHTML = '<span class="red-text">'+Text+'</span>' + string.split(substringCone).pop();
            Text = '';
          }
          
      });
    },
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        Sage.product_titles_red();
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {

        $(".home-icon img").css('cursor', 'pointer');

        $(".home-icon img").hover(
          function(){ 
          $(this).css("opacity", "0.5");}, 
          function(){ 
          $(this).css("opacity", "1");}
          );

        $(".home-icon1 img").click(function(){
          window.location = '/product-category/coffee-carafes/';
        });
        $(".home-icon2 img").click(function(){
          window.location = '/product-category/kettles-percolators/';
        });
        $(".home-icon3 img").click(function(){
          window.location = '/product-category/coffee-filters-infusers/';
        });
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'single' : {
      init: function() {
        $(".related h4").replaceWith("<h4>Replacement Parts</h4>");
      }
    },
    'woocommerce' : {
      init: function(){
            var termClass = $("body").hasClass("term-coffee-carafes");
            var taxClass = $("body").hasClass("tax-brand");
                if (termClass || taxClass){
                  $(".sidebar .widget_text").css('display', 'block');
                }
      }
    },
    'archive' : {
      init: function(){
            var termClass = $("body").hasClass("term-coffee-carafes");
            var taxClass = $("body").hasClass("tax-brand");
                if (termClass || taxClass){
                  $(".sidebar .widget_text").css('display', 'block');
        }
      }
    },
    'tax_brand' : {
      init: function() {
        // load title
        $('.title_subtitle_holder .title_subtitle_holder_inner span').text(arcTitle);
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);


})(jQuery); // Fully reference jQuery after this point.
